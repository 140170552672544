<template>
  <div class="jsmb-account-information-detail">
    <h2 class="jsmb-title-detail is-size-4 has-text-text">CGV</h2>
   	
		<h1>CONDITIONS GÉNÉRALES</h1>
    
    <p>Les présentes conditions générales d’utilisation et de vente («&nbsp;CG&nbsp;») sont proposées par la société JeStockeMonBateau à Les Marches, 05170 Orcières</p>
    <p>Les CG ont pour objet de régir les conditions d’utilisation de la plateforme de mise en relation accessible à l’adresse www.jestockemonbateau.fr (le «&nbsp;Site&nbsp;») par les Skippers et les Stockeurs tels que définis ci-après inscrits sur le Site.</p>
    <p>En outre, les CG ont vocation à définir les droits et obligations de chacun sur le Site.</p>
    <p>Toute utilisation du Site est soumise à l’acceptation préalable expresse des CG.</p>
    <p>Le Site est hébergé par OVH, France.</p>
    <p>Le directeur de la publication est Monsieur Brice GUINTRAND.</p>
    
    <h2>ARTICLE 1 : DÉFINITIONS</h2>

    <p><strong>Bateau</strong> désigné un bateau de plaisance, propriété d’un Skippeur et qui est destiné à être stocké par le Stockeur.</p>
    <p><strong>Skippers</strong> désignent toute personne physique propriétaire d’un Bateau qui souhaite faire appel aux services d’un Stockeur pour assurer une mission de stockage temporaire dudit Bateau.</p>
    <p><strong>Stockeurs</strong> désignent une personne physique et/ou morale qui est disposée à proposer un local de stockage à un Skipper pour une durée limitée.</p>
    <p><strong>Compte</strong> désigne l’espace personnel de chaque Utilisateur sur la Plateforme.</p>
    <p><strong>Plateforme</strong> désigne l’outil mis à disposition par la Société sur le Site et permettant la mise en relation des Skippers et des Stockeurs.</p>
    <p><strong>Site</strong> désigne le site Internet accessible à l’adresse www.jestockemonbateau.fr</p>
    <p><strong>Utilisateur</strong> désigne à la fois un Skipper et/ou un Stockeur.</p>
    
    <h2>ARTICLE 2&nbsp;: OBJET</h2>

    <p>La Société propose sur le Site une plateforme de mise en relation entre les Stockeurs et les Skippers afin de permettre à ces derniers de solliciter les services des Stockeurs pour la mise à disposition d’un espace de stockage et ce, par l’intermédiaire de la plateforme (la «&nbsp;<strong>Plateforme</strong>&nbsp;») mise à disposition par la Société.</p>
    <p>En outre, la Société propose en collaboration avec un prestataire de paiement la rémunération des Stockeurs par les Skippers.</p>
    <p>Les CG régissent les conditions d’utilisation et les règles applicables à la relation entre d’une part la Société et les Stockeurs et les Skippers et d’autre part entre les Stockeurs et les Skippers.</p>
    
    <h2>ARTICLE 3 : DISPONIBILITE DE LA PLATEFORME</h2>

    <p>La Plateforme est accessible 24 heures sur 24 heures et 7 jours sur 7.</p>
    <p>Toutefois, la Société ne fournit aucune garantie concernant cette disponibilité.</p>
    <p>Ainsi, les Utilisateurs sont informés et reconnaissent que l’accès à la Plateforme pourra être suspendu ou altéré notamment pour des opérations de maintenance et ce, sans indemnisation possible des Utilisateurs.</p>
    <p>En outre, les Utilisateurs sont seuls responsables des moyens matériels qu’ils mettent en œuvre pour accéder à la Plateforme. Notamment, les Utilisateurs supportent tous les frais de connexion à la Plateforme à leur charge.</p>
    
    <h2>ARTICLE 4 : CONDITIONS D’INSCRIPTION SUR LE SITE ET LA PLATEFORME</h2>

    <p>La Plateforme est accessible&nbsp;:</p>
    <ul>
      <li>A toute personne physique âgée d’au moins 18 ans et jouissant de la capacité juridique lui permettant de conclure des actes juridiques ;</li>
      <li>A toute entreprise régulièrement enregistrée et inscrite auprès d’un registre du commerce et des sociétés ou tout équivalent.</li>
    </ul>
    <p>L’Utilisateur qui souhaite bénéficier des services offerts par la Plateforme est invité à créer un Compte sur la Plateforme en se connectant au Site et en suivant les instructions qui lui sont indiquées à cet égard.</p>
    <p>Plus particulièrement, l’Utilisateur doit compléter et renseigner un certain nombre d’informations le concernant et notamment sans que cela soit limitatif&nbsp;:</p>
    <ul>
      <li>Identité (nom, prénom ou raison sociale le cas échéant),</li>
      <li>Adresse,</li>
      <li>Téléphone,</li>
      <li>Adresse électronique,</li>
      <li>Identifiant,</li>
      <li>Mot de passe.</li>
    </ul>
    <p>Il est en outre précisé&nbsp;que la Société se réserve le droit de demander des documents ou éléments supplémentaires aux Utilisateurs en tant que de besoin et à sa libre discrétion tels que, sans que cela soit limitatif, des justificatifs d’identité ou extrait Kbis, le cas échéant.</p>
    <p>En tout état de cause, il est précisé que les Stockeurs et les Skippers devront fournir des informations exactes et vérifiables à tout moment. A défaut, la Société se réserve de suspendre ou de refuser l’accès au Site et à la Plateforme à tout Utilisateur qui ne respecterait pas ces conditions.</p>
    <p>Une fois ces informations renseignées, l’Utilisateur se voit confirmer son inscription à la Plateforme par email à l’adresse électronique indiquée lors de son inscription.</p>
    <p>Dès confirmation, l’Utilisateur peut accéder à la Plateforme et à son Compte en se connectant à l’aide de son identifiant et de son mot de passe.</p>
    <p>Le Compte en ligne de l’Utilisateur permet de gérer la mise en relation entre les Stockeurs et les Skippers.</p>
    
    <h2>ARTICLE 5 : DESCRIPTION DU FONCTIONNEMENT DE LA PLATEFORME ET RESERVATION</h2>

    <p>Une fois son inscription confirmée par la Société, l’Utilisateur peut utiliser les services de mise en relation offerts par la Plateforme.</p>
    <p>A cet égard, les Utilisateurs sont informés et acceptent que la Société met à disposition un outil informatique accessible en ligne depuis le Site par lequel un Stockeur et un Skipper peuvent être mis en relation.</p>
    <p>Cette mise en relation s’effectue sur la base des besoins exprimés par le Skipper d’une part et des éléments de réponse à ce besoin indiqués par le Stockeur d’autre part.</p>
    <p>Dès confirmation du Stockeur, le Skippeur reconnait qu’il est engagé pendant la durée sélectionné sous d’un droit d’annulation dans un délai de 48 heures à compter de la confirmation du Stockeur et ce, sans obligation de payer le montant dû au Stockeur mais avec obligation de payer la commission due à la Société pour la mise en relation.</p>
    
    <h2>ARTICLE 6 : PRIX DES SERVICES PROPOSÉS PAR LE SITE</h2>

    <p>L’accès à la Plateforme par les Stockeurs et les Skippers est gratuit.</p>
    <p>Seules les mises en relation entre les Stockeurs et les Skippers impliquent une participation financière sous forme de commission au bénéfice de la Société calculée sur le montant demandé par le Stockeur au Skipper.</p>
    <p>A cet égard, la Société propose une grille tarifaire composée de plusieurs offres en fonction des besoins du Skipper :</p>
    <br>
    <strong>Tarif conseillé fixe en fonction de la longueur de la place :</strong>
    <ul>
      <li>pour une place inférieure ou égale a 5 mètres de long le prix fixe (sans options et a plus de 50km de la cale) est à 49 euros</li>
      <li>pour une place inférieure ou égale a 10 mètres de long le prix fixe est à 79 euros</li>
      <li>pour une place supérieure a 10 mètres de long le prix fixe est à 99 euros</li>
    </ul>
    <strong>Vient se rajouter à ce prix fixe la distance de la première cale de mise à l'eau:</strong>
    <ul>
      <li>rayon entre 0-5 km : + 90 euros</li>
      <li>rayon entre 5-10 km : + 65 euros</li>
      <li>rayon entre 10-30 km : + 40 euros</li>
      <li>rayon entre 30-50 km: + 25 euros</li>
    </ul>
    
    <h2>ARTICLE 7&nbsp;: CONDITION DE PAIEMENT DU PRIX DES SERVICES DE MISE EN RELATION</h2>

    <p>Le paiement par les Skippers du prix de l’offre souscrite s’effectue au moment de la souscription par les Skippers par carte bancaire.</p>
    <p>Il est précisé que le paiement est adossé à la mise en relation entrainant un service rémunéré de la part d’un Stockeur.</p>
    <p>Le montant de la rétribution du Stockeur est préalablement négocié de gré à gré entre les Stockeurs et les Skippers et fixé avant le démarrage de la mission sur la base d’un montant forfaitaire minimum recommandé par la Société mais non obligatoire.</p>
    
    <h2>ARTICLE 8 : REMUNERATION DES STOCKEURS</h2>

    <p>L’accès et l’utilisation de la Plateforme sont gratuits pour les Stockeurs.</p>
    <p>Seules les prestations des Stockeurs sont rémunérées par les Skippers dans les conditions des présentes.</p>
    
    <h2>ARTICLE 9 : RESPONSABILITE DE LA SOCIETE</h2>

    <p>La Société n’agit qu’en tant qu’intermédiaire de mise en relation entre les Stockeurs et les Skippers.</p>
    <p>Par ailleurs, la Société ne procède à aucun contrôle des compétences des Stockeurs qui sont parfaitement indépendants et aucunement soumis à la Société. La Société et les Stockeurs ne sauraient former une entreprise commune.</p>
    <p>Aussi, la responsabilité de la Société ne saurait être engagée dans ce cadre&nbsp;; les Stockeurs fournissant leurs prestations sous leur seul contrôle.</p>
    <p>Plus particulièrement, la Société n’est pas responsable de tout dommage causé par les Stockeurs dans le cadre de leur intervention auprès du Skipper.</p>
    <p>Par ailleurs, en ce qui concerne la mise à disposition de la Plateforme, il est entendu que la Société fournit la Plateforme en l’état et ne fournit aucune garantie quant à son accès et à sa disponibilité en tout temps&nbsp;; ce que les Stockeurs et les Skippers reconnaissent et acceptent expressément.</p>
    <p>Enfin, les services fournis par la Société ne relèvent que d’une obligation de moyens à l’exclusion de toute obligation de résultat, ce que les Utilisateurs reconnaissent et acceptent expressément par l’acceptation des présentes CG.</p>
    
    <h2>ARTICLE 10 : LIMITATION DE RESPONSABILITE DE LA SOCIETE</h2>

    <p>La Société s’engage à mettre en œuvre tous les moyens nécessaires à sa disposition afin d’assurer la fourniture et la mise à disposition de la Plateforme aux Utilisateurs.</p>
    <p>Toutefois, la Société ne saurait être tenue responsable dans les cas suivants :</p>
      <ul>
        <li>interruptions, pannes, modifications et dysfonctionnements de la Plateforme&nbsp;;</li>
        <li>la perte de données ou d’informations&nbsp;;</li>
        <li>impossibilité d’accéder à la Plateforme&nbsp;;</li>
        <li>dommages directs ou indirects causés aux Utilisateurs, quelle qu’en soit la raison, résultant des Produits et/ou de l’accès et de l’utilisation de la Plateforme.</li>
      </ul>
    
    <p>La responsabilité de la Société ne pourra être engagée que pour les dommages directs subis par un Utilisateur, résultant uniquement d’un manquement aux obligations contractuelles de la Société telles que définies aux présentes CG.&nbsp; En tout état de cause, il est rappelé aux Utilisateurs que le Société a souscrit une police d’assurance auprès d’une compagnie d’assurance reconnue.</p>
    <p>Les Utilisateurs renoncent par avance à toute demande de réparation à quelque titre que ce soit, des dommages indirects tels que le manque à gagner, la perte de chance, le préjudice commercial ou financier.</p>
    <p>Tout Utilisateur est seul responsable des dommages causés aux tiers et aux autres Utilisateurs et des conséquences des réclamations ou actions qui pourraient en découler.</p>
    <p>La Société n’intervient pas entre les Skippers et les Stockeurs dans le cadre de l’exécution de la prestation par le Stockeur qui en est seul responsable.</p>
    <p>A ce titre, le Stockeur assume seul la responsabilité de son intervention pour le compte du Skipper.</p>
    
    <h2>ARTICLE 11 : GARANTIES DES SKIPPERS</h2>

    <p>Les Skippers garantissent, lors de leur inscription sur la Plateforme, qu’ils disposent de tous les droits nécessaires pour entreposer leur bateau sur un terrain ou emplacement.</p>
    <p>Les Skippers garantissent avoir pris toutes les dispositions nécessaires à l’assurance du Bateau qui est disposé à l’hivernage sur un emplacement mis à disposition par le Stockeur.</p>
    <p>Les Skippers garantissent la Société et les Stockeurs contre toute action de tiers ou réclamation concernant les services fournis.</p>
    
    <h2>ARTICLE 12 : GARANTIE DES STOCKEURS</h2>

    <p>Les Stockeurs garantissent, lors de leur inscription sur la Plateforme, qu’ils disposent de tous les droits et de toutes les compétences nécessaires pour proposer leurs services aux Skippers par le biais de la Plateforme.</p>
    <p>Par ailleurs, les Stockeurs garantissent avoir pris les dispositions nécessaires à assurer le ou les lieux d’entreposage mis à disposition.</p>
    <p>En aucun cas, les Stockeurs ne proposent de service de gardiennage. Le Bateau n’est ainsi pas laissé sous la garde du Stockeur&nbsp;; ce que le Skipper reconnaît et accepte.</p>
    
    <h2>ARTICLE 13 : RELATIONS ENTRE LES SKIPPERS ET LES STOCKEURS</h2>

    <p>Il appartient aux Skippers et aux Stockeurs de se mettre d’accord entre eux par tous moyens afin d’organiser les conditions de leur relation.</p>
    <p>En aucun cas, la Société n’est responsable des conditions de la relation entre les Skippers et les Stockeurs&nbsp;; ce que ces derniers reconnaissent et acceptent.</p>
    <p>Ainsi, il leur appartient de prendre toutes les mesures nécessaires à l’organisation de cette relation.</p>
    
    <h2>ARTICLE 14 : PROPRIETE INTELLECTUELLE</h2>

    <p>Les Utilisateurs sont informés que la Plateforme et l’ensemble des éléments (photographies, vidéos, textes, contenus audio,&nbsp;…) la composant sont couverts par des droits de propriété intellectuelle dont la Société est seule titulaire.</p>
    <p>Cela inclut également l’ensemble des dénominations utilisées pour désigner la Plateforme et le Site.</p>
    <p>A cet égard, tout acte de reproduction ou d’utilisation non autorisé par la Société constitue un acte de contrefaçon qui peut être poursuivi comme tel tant sur le plan civil que le plan pénal.</p>
    <p>A cet égard, la Société autorise uniquement les Utilisateurs à utiliser les éléments couverts par un droit de propriété intellectuelle aux seules fins d’exécution des présentes et pendant la durée d’application des présentes.</p>
    <p>Les Utilisateurs s’engagent à ne modifier aucun de ces éléments d’aucune manière ou à en faire une utilisation non conforme aux présentes.</p>
    <p>Par ailleurs, la Société reconnait et accepte que les contenus publiés par les Utilisateurs sur la Plateforme leur appartiennent et qu’ils détiennent l’ensemble des droits de propriété intellectuelle sur ces contenus.</p>
    <p>Toutefois, aux fins de permettre l’exploitation de la Plateforme et la publication de ces contenus, les Stockeurs concèdent expressément la Société&nbsp;:</p>
    <p>–&nbsp; &nbsp; le droit d’exploitation non exclusif, transférable, sous licenciable, à titre gracieux, pour le monde entier concernant l’ensemble du contenu et notamment sur les photographies, textes, vidéos, illustrations, marques, logotypes, titres (ci-après le « Contenu »), au fur et à mesure de leur publication sur la Plateforme ainsi (ii) qu’une licence sur l’ensemble des droits de propriété intellectuelle afférant aux annonces et notamment sur les droits d’auteurs sur les éléments utilisés dans son annonce, tels que les photographies, textes, vidéos, dessins, illustrations, et ce pour toute la durée légale de ses droits de propriété intellectuelle et pour le monde entier.</p>
    <p>Les droits ainsi concédés incluent le droit de reproduire, représenter, diffuser, adapter, modifier, réaliser une œuvre dérivée, traduire tout ou partie du contenu par tous procédés, sous quelque forme que ce soit et sur tous supports connus ou inconnus à ce jour dans le cadre de l’activité de la Société et ce à des fins commerciales ou non et notamment publicitaires, ainsi que dans le cadre d’une diffusion sur les réseaux sociaux le cas échéant.</p>
    
    <h2>ARTICLE&nbsp;15&nbsp;: OBLIGATIONS DES UTILISATEURS</h2>

    <p>Dans le cadre de leur utilisation et de l’accès à la Plateforme, les Utilisateurs s’engagent&nbsp;:</p>
    <ul>
      <li>à ne pas accéder à la Plateforme à des fins illicites ou contraires aux bonnes mœurs&nbsp;;</li>
      <li>à ne pas publier de contenu en violation des droits d’un tiers notamment des droits de propriété intellectuelle&nbsp;;</li>
      <li>à fournir des informations exactes et vérifiables&nbsp;;</li>
      <li>à ne pas fournir ou publier des éléments qui sont contraires à l’ordre public et aux bonnes mœurs,</li>
      <li>à ne pas perturber le fonctionnement normal du Site et de la Plateforme.</li>
    </ul>
      
    <h2>ARTICLE 16&nbsp;: DONNEES A CARACTERE PERSONNEL</h2>

    <p>Les Utilisateurs sont informés que la Société collecte des données à caractère personnel les concernant aux seules fins de permettre l’exécution des présentes et la mise à disposition de la Plateforme.</p>
    <p>Ces données à caractère personnel sont collectées et traitées conformément à la législation applicable et notamment la loi information et liberté du n° 78-17 du 6 janvier 1978.</p>
    <p>Il est rappelé que la Société s’engage à ne communiquer aucune information, coordonnée concernant les Utilisateurs et ne procédera à aucune vente de fichiers.</p>
    <p><strong>Quelles données à caractère personnel sont collectées ?</strong></p>
    <p>La Société collecte et traite les données suivantes relatives aux Utilisateurs : civilité, nom, prénom, date de naissance, adresse postale, adresse électronique, l’adresse IP ainsi que les informations recueillies à l’aide de cookies.</p>
    <p><strong>Pour quelle raison les données sont-elles collectées ?</strong></p>
    <p>Les données à caractère personnel sont collectées aux fins de permettre à la Société de fournir ses services sur le Site et la Plateforme.</p>
    <p>Il est rappelé aux Utilisateurs que les données ainsi collectées visent à permettre le bon fonctionnement du Site et de la Plateforme et ainsi la mise en relation des Utilisateurs entre eux.</p>
    <p>En outre, les Utilisateurs sont informés que seules les données strictement nécessaires aux finalités recherchées sont collectées. A cet égard, la Société applique le principe de la minimisation de la collecte des données. La Société ne collectera pas plus de données que ce dont elle a besoin pour assurer la fourniture du Site et de la Plateforme.</p>
    <p><strong>Quelle durée de conservation ?</strong></p>
    <p>Les données à caractère personnel sont collectées et traitées uniquement afin de répondre aux objectifs poursuivis par la Société.</p>
    <p>Dans ce cadre, la Société ne conservera pas les données collectées pour une durée excédant l’atteinte de l’objectif qu’elle s’est fixée.</p>
    <p><strong>Les droits des personnes concernées ?</strong></p>
    <p>La Société s’engage à assurer un traitement des données conforme aux droits des personnes concernées par un traitement de données à caractère personnel.</p>
    <p>Ainsi, la Société garantit que les Utilisateurs bénéficient notamment d’un droit d’accès, de suppression, de modification, d’opposition et de rectification concernant les données collectées les concernant.</p>
    <p><strong>Les transferts de données</strong></p>
    <p>Les Utilisateurs sont informés de ce que leurs données sont susceptibles d’être communiquées à des tiers domiciliées dans l’Union européenne et ce, aux seules fins de permettre le bon fonctionnement du Site et de la Plateforme ainsi que dans des Etats assurant un niveau de protection des données personnelles comparable à celui prévu par la législation de l’Union européenne.</p>
    <p>En outre, les Utilisateurs sont spécifiquement informés que la Société communiquera les données de l’un à l’autre et inversement.</p>
    <p><strong>La sécurité des données à caractère personnel</strong></p>
    <p>La Société assure la sécurité des données à caractère personnel concernant les Utilisateurs en prenant toutes les mesures appropriées de façon à garantir l’absence d’intrusion dans ses fichiers.</p>
    <p>Ce faisant, la Société contrôle notamment l’accès aux données des Utilisateurs.</p>
    <p><strong>Cookies</strong></p>
    <p>La Société utilise des cookies pour lui permettre de réaliser des statistiques de visites du Site ainsi que pour assurer un fonctionnement optimal du Site et de la Plateforme et la diffusion de publicités ciblées.</p>
    <p>Par l’application des présentes, les Utilisateurs consentent au dépôt de cookies sur leur matériel informatique.</p>
    
    <h2>ARTICLE 17 : EVALUATION RECIPROQUE</h2>

    <p>Les Stockeurs reconnaissent et acceptent d’être évalués quant à leur prestation par les Entreprises et réciproquement.</p>
    <p>Ce faisant, les Utilisateurs reconnaissent que lesdites évaluations pourraient permettre à la Société, à sa libre discrétion, de se réserver le droit de suspendre l’accès à la Plateforme à tout Utilisateur qui ne respecterait pas les conditions des présentes et qui ne répondrait pas aux standards de qualité compte tenu des avis qui auraient pu être laissés par d’autres Utilisateurs.</p>
    
    <h2>ARTICLE 18 : MODIFICATION DES CGU</h2>

    <p>Les Utilisateurs sont informés que la Société pourra procéder à des modifications des CGU à sa discrétion ou pour répondre à la législation applicable.</p>
    <p>Les Utilisateurs sont informés d’une telle modification directement sur le Site le moment venu.</p>
    <p>Toute modification est considérée comme acceptée dès lors que les Utilisateurs continuent d’Utiliser la Plateforme.</p>
    
    <h2>ARTICLE 19 : DUREE ET RESILIATION</h2>

    <h3 class="has-text-weight-bold">I) Les conditions de location</h3>
    <p>Le bateau skipper, propriétaire du bateau, en accord avec le bateau stockeur, celui qui met à disposition un emplacement contre rémunération, dispose deux possibilités de location.</p>
    <ul>
      <li><strong>La courte durée, moins de 30 jours :</strong> dans ce cas, l’adhésion peut se faire pour une journée, un week-end, une semaine, dix jours, etc., jusqu’à un maximum de 29 jours pleins. Le tarif consenti entre les deux parties <strong style="text-decoration:underline">est réglé dans son intégralité à la signature du contrat.</strong></li>
      <li><strong>La longue durée, au-delà de 30 jours :</strong> l’adhésion est conclue pour un mois minimum puis le nombre de mois souhaités jusqu’à une année. <strong style="text-decoration:underline">La première mensualité est payée à la signature du contrat ; les règlements suivants sont effectués de façon mensuelle à la date anniversaire du contrat.</strong></li>
    </ul>

    <h3 class="has-text-weight-bold">II) Les conditions de résiliation</h3>
    <p>Le bateau skipper, propriétaire du bateau, en accord avec le bateau stockeur, celui qui met à disposition un emplacement contre rémunération, dispose deux possibilités de location.</p>
    <ul>

      <li><strong>Dans le cas d’un stockage courte durée de moins de trente jours :</strong> Si l’annulation intervient <span style="text-decoration:underline">avant le début de la location,</span>&nbsp;<strong>le remboursement est consenti</strong> (hors frais de commission automatiquement retenus) pour le bateau skipper.<br>
      Si la résiliation est demandée <span style="text-decoration:underline">alors que la location a débuté,</span> celle-ci est rendue caduque mais <strong>le règlement intégral effectué lors de la signature ne pourra connaitre aucun remboursement.</strong>
      </li>

      <li><strong>Dans le cas d’un stockage longue durée (plus de trente jours), dont la période a été préalablement établie entre les parties :</strong> Si la demande d’annulation du contrat est souhaitée <span style="text-decoration:underline">avant que ne commence la location,</span> comme pour la location courte durée, <strong>le remboursement est consenti</strong> (hors frais de commission automatiquement retenus) pour le bateau skipper.<br>
      <strong>Si la résiliation d’un contrat de location longue durée est demandée durant l’exécution du contrat, le bateau skipper doit alors effectuer une demande écrite au minimum 7 jours avant la date anniversaire du contrat en cours. Le non-respect du préavis entraîne la prolongation de la location d’un mois.Chaque mois de location entamée est dû. Les frais de commission sont également retenus.
      </strong></li>
    </ul>
    <p>Les tarifs fixés entre bateau skipper et bateau stocker seront libres, <strong>JeStockeMonBateau.fr</strong> s’accordant le droit de fournir une grille tarifaire basée sur différents critères.</p>
    
    <h2>ARTICLE 20 : LOI APPLICABLE ET JURIDICTION COMPETENTE</h2>

    <p>Les présentes CG sont régies par la législation française.</p>
    <p>En cas de difficulté concernant leur interprétation ou leur exécution, les parties entendent donner compétence à toute juridiction compétente selon le droit commun applicable en la matière.</p>
    
    <h2>ARTICLE 21 : LIENS HYPERTEXTES</h2>

    <p>Le Site peut contenir des liens dirigeant les Utilisateurs vers d’autres sites Internet qui ne sont pas contrôlés par la Société.</p>
    <p>Ce faisant, la Société n’assume aucune responsabilité&nbsp;quant à ces liens ni au contenu des sites ainsi visés.</p>
    <p>Il ne s’agit que de liens donnés à titre informatif&nbsp;; ce que les Utilisateurs reconnaissent et acceptent.</p>
    
    <h2>ARTICLE 22 : DISPOSITIONS DIVERSES</h2>

    <p>Dans le cadre de la législation applicable concernant les plateformes de mise en relation, la Société invite les Utilisateurs à prendre connaissance de leurs obligations fiscales et sociales en cliquant sur les liens suivants :</p>
    <p><a href="https://www.impots.gouv.fr/portail/node/10841" target="_blank" rel="noopener noreferrer">Obligations fiscales</a></p>
    <p><a href="http://www.securite-sociale.fr/Vos-droits-et-demarches-dans-le-cadre-des-activites-economiques-entre-particuliers-Article-87">Obligations sociales</a></p>
    <p>En outre, il est considéré qu’en application de la législation applicable, les Utilisateurs ont reçu une information claire, loyale et transparente contenue notamment dans les présentes&nbsp;; ce qu’ils reconnaissent et acceptent.</p>

    <h2>STRIPE</h2>
    <p>En acceptant nos CGV, vous acceptez les CGV de notre partenaire de paiement Stripe</p>
    <a href="https://stripe.com/fr/legal#translation" target="_blank">Lire les cgv de Stripe</a>
        


    
  </div>
</template>

<script>
export default {
  name: 'Cgv',
  metaInfo () {
    let siteName = this.$store.state.globalConstant.SITE_NAME,
      baseUrl = this.$store.state.globalConstant.BASE_URL,
      canonical = this.$router.resolve({ name: 'Cgv' });

    let title = (siteName+' - Conditions générales de ventes - Stockage de bateaux chez les particuliers et professionnels - Hivernage Bateau - Stockage bateau hors d\'eau'),
      description = siteName+' est le spécialiste du stockage de bateaux chez les particuliers et professionnels avec une assurance ! La première plateforme dans le monde mettant en relation des propriétaires d\'un jardin, d\'un abri, d\'un box ou d\'un garage, avec des plaisanciers qui recherchent un endroit où stocker leur bateau.';

    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      titleTemplate: title,
      link: [
          { rel: 'canonical', href: (baseUrl+canonical.href) },
      ],
      meta: [
        { 
          vmid: 'description', 
          name: 'description', 
          content: description 
        },
        {
          'property': 'og:title',
          'content': title,
          'vmid': 'og:title'
        },
        {
          'property': 'og:description',
          'content': description,
          'vmid': 'og:description'
        },
        {
          'property': 'og:url',
          'content': (baseUrl+this.$route.fullPath),
          'vmid': 'og:url'
        },
        {
          'property': 'og:image',
          'content': (baseUrl+require('./../../../assets/og-image.png')),
          'vmid': 'og:image'
        },
        {
          'property': 'og:image:secure_url',
          'content': (baseUrl+require('./../../../assets/og-image.png')),
          'vmid': 'og:image:secure_url'
        },
        {
          'property': 'og:image:alt',
          'content': title,
          'vmid': 'og:image:alt'
        },
        {
          'name': 'twitter:title',
          'content': title,
          'vmid': 'twitter:title'
        },
        {
          'name': 'twitter:description',
          'content': description,
          'vmid': 'twitter:description'
        },
       {
          'name': 'twitter:image',
          'content': (baseUrl+require('./../../../assets/tw-card.jpg')),
          'vmid': 'twitter:image'
        },
      ]
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
h1,
h2 {
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
p,
strong{
  margin-bottom: 1rem;
}
ul {
  list-style-type: disc;
  list-style-position: inside;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
li {
  margin-left: 1rem;
  margin-bottom: 1rem;
}

/*
  input:not([type="radio"]){
    margin-bottom: 0.5rem;
    border:none;
    box-shadow: none;
  }
  textarea {
    box-shadow: none;
    border:none
  }
  input[type=radio] {
  border: 1px solid #df5051;
  padding: 0.5em;
  -webkit-appearance: none;
}
input[type=radio]:checked {
  background-size: 9px 9px;
  background-color: #df5051;
}
input[type=radio]:focus {
  outline-color: none;
}
.radio {
  font-size:0.85rem
}*/


</style>
